import React from "react";
import Sidebar from "./sidebar";
import Topbar from "./topbar";
import Header from "./header";
import styled from "styled-components";
import { mobile } from "../responsive";

const ScrollContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 60px;
    border: none;
    background-color: black;
    color: white;
    overflow: hidden;
`;

const ScrollText = styled.div`
text-align: center;
display: block;
cursor: pointer;
color: red;
font-weight: 600;
${mobile({ display: "none" })}
/* animation properties */
-moz-transform: translateX(-100%);
-webkit-transform: translateX(-100%);
transform: translateX(-100%);

-moz-animation: my-animation 15s linear infinite;
-webkit-animation: my-animation 15s linear infinite;
animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
from { -moz-transform: translateX(-100%); }
to { -moz-transform: translateX(100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
from { -webkit-transform: translateX(-100%); }
to { -webkit-transform: translateX(100%); }
}

@keyframes my-animation {
from {
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
to {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}


`;

const MobileScrollText = styled.div`
color:red;
text-align: center;
display: none;
${mobile({ display: "block" })}
/* animation properties */
-moz-transform: translateX(-100%);
-webkit-transform: translateX(-100%);
transform: translateX(-100%);

-moz-animation: my-animation 15s linear infinite;
-webkit-animation: my-animation 15s linear infinite;
animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
from { -moz-transform: translateX(-100%); }
to { -moz-transform: translateX(100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
from { -webkit-transform: translateX(-100%); }
to { -webkit-transform: translateX(100%); }
}

@keyframes my-animation {
from {
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
to {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
`;

const Layout = (props) => {
    return (
        <main>
            <div className="lg:hidden">
                <Header />
            </div>
            <ScrollContainer>
                <ScrollText onClick={() => (window.location.href = "/terms")}>
                    D&Dream Announcement - Important Message from Management -{" "}
                    <span className="bg-orange-400 text-black font-bold p-2 rounded-full">
                        {" "}
                        Click here
                    </span>{" "}
                    to view.
                </ScrollText>
                <MobileScrollText
                    onClick={() => (window.location.href = "/terms")}
                >
                    Message from Management -{" "}
                    <span className="bg-orange-400 text-black font-semibold p-2 rounded-full">
                        {" "}
                        Click here
                    </span>{" "}
                </MobileScrollText>
            </ScrollContainer>
            <div className="z-30 flex flex-auto h-full w-full relative min-h-screen">
                <Sidebar />
                <div className="wrapper w-full lg:w-10/12 flex flex-col">
                    <Topbar className="flex-grow-0" />
                    <div className="content bg-black w-full flex-grow overflow-auto">
                        {props.children}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Layout;
